import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('questionnaires', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`questionnaires/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('questionnaires', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.put(`questionnaires/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`questionnaires/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        seasons: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('seasons', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}