<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Questionnaire</h5>
            </b-card-header>
            <b-card-body>
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="submit">
                        <b-row>
                            <b-col
                                cols="12"
                                md="2"
                                class="mb-md-0 mb-2"
                            >
                                <label>Season Filter</label>
                                <b-form-select
                                    size="sm"
                                    v-model="questionnaire.season_id"
                                    @change="changeSeason"
                                    readonly
                                >
                                    <b-form-select-option
                                        v-for="season in seasons"
                                        :key="season.id"
                                        :value="season.id"
                                        :selected="season.isRunning"
                                    >
                                        {{ season.startYear }}/{{ season.endYear }}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col
                                cols="12"
                                md="1"
                                class="mb-md-0 mb-2"
                            >
                                <b-form-group labe-for="cycle" label="Cycle">
                                    <validation-provider #default="{ errors }" name="Cycle">
                                        <b-form-input
                                            id="cycle"
                                            name="cycle"
                                            v-model="questionnaire.cycle"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            value=""
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.cycle">{{ serverErrors.cycle[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col
                                cols="12"
                                md="1"
                                class="mb-md-0 mb-2"
                            >
                                <b-form-group labe-for="sections" label="Sections">
                                    <validation-provider #default="{ errors }" name="Sections">
                                        <b-form-input
                                            id="sections"
                                            name="sections"
                                            v-model="questionnaire.sections"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            value=""
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.sections">{{ serverErrors.sections[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="2">
                                <b-form-group labe-for="startDate" label="Visit Start">
                                    <validation-provider #default="{ errors }" name="StartDate" rules="">
                                        <b-form-datepicker
                                            id="startDate" v-model="questionnaire.startDate" name="satrtDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.startDate">{{ serverErrors.startDate[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="2">
                                <b-form-group labe-for="endDate" label="Visit End">
                                    <validation-provider #default="{ errors }" name="EndDate" rules="">
                                        <b-form-datepicker
                                            id="endDate" v-model="questionnaire.endDate" name="endDate"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.endDate">{{ serverErrors.endDate[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="2">
                                <b-form-group labe-for="status" label="Status">
                                    <validation-provider #default="{ errors }" name="Status" rules="">
                                        <b-form-checkbox
                                            checked="false"
                                            class="custom-control-success"
                                            name="status"
                                            switch
                                            v-model="questionnaire.status"
                                        >
                                            <span class="switch-icon-left">
                                                <feather-icon icon="CheckCircleIcon" />
                                            </span>
                                            <span>Active?</span>                                 
                                        </b-form-checkbox>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.status">{{ serverErrors.status[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="1" class="pt-2">
                                <b-button
                                    size="sm"
                                    variant="primary"
                                    @click="submit"
                                >
                                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                                    <span v-if="!saving">Create</span>
                                    <span v-if="saving">Creating...</span>
                                </b-button>
                            </b-col>
                            <b-col cols="12" md="1" class="pt-2">
                                <b-button
                                    size="sm"
                                    variant="outline-secondary"
                                    @click="resetForm"
                                >
                                    Reset
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-card-body>
            <!-- <b-card-body>{{ questionnaire }}</b-card-body> -->
        </b-card>
        <!-- ./Filters -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                        </div>
                    </b-col>
                    <!-- ./Search -->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <!-- Column: Status -->
                <template #cell(status)="data">
                    <b-badge
                        pill
                        :variant="`light-${resolveStatusVariant(data.item.status !== 1? 0 : (reachedEndDate(data.item.endDate)? 0 : data.item.status))}`"
                        class="text-capitalize"
                    >
                        {{ data.item.status !== 1? 'Closed' : (reachedEndDate(data.item.endDate)? 'Closed' : (data.item.status === 1? 'On Progress' : 'Closed')) }}
                    </b-badge>
                </template>

                <template #cell(sections)="data">
                    {{ data.item.sections.length }}
                </template>

                <template #cell(questions)="data">
                    {{ data.item.questions.length }}
                </template>

                <template #cell(startDate)="data">
                    {{ data.item.startDate | moment("DD/MM/YYYY") }}
                </template>

                <template #cell(endDate)="data">
                    {{ data.item.endDate | moment("DD/MM/YYYY") }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                    >

                        <template #button-content>
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                        </template>
        
                        <b-dropdown-item @click="invokeUpdateForm(data.item)" :title="data.item.season.status !== 1? 'Edit not allowed' : ''">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
        
                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRecords"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>

        </b-card>
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
    import { 
        BRow, BCol, BCard, BCardBody, BCardHeader,
        BForm, BFormGroup, BFormSelect, BFormSelectOption, BFormInput, BFormDatepicker, BFormCheckbox, BButton,
        BTable, BPagination, BBadge, BDropdown, BDropdownItem, BSpinner,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import store from '@/store'
    import { ref, onMounted, onUnmounted } from '@vue/composition-api'
    import questionnaireStoreModule from '@/views/cromis/visitings/questionnaires/questionnairesStoreModule'
    import useQuestionnairesList from '@/views/cromis/visitings/questionnaires/useQuestionnairesList'
    import moment from 'moment'
    export default {
        props: {},
        components: {
            BRow, BCol, BCard, BCardBody, BCardHeader,
            BTable, BPagination, BBadge, BDropdown, BDropdownItem, BSpinner,
            BForm, BFormGroup, BFormSelect, BFormSelectOption, BFormInput, BFormDatepicker, BFormCheckbox, BButton, vSelect,
            ValidationProvider, ValidationObserver,
        },
        directives: {},

        setup(props, context) {
            const saving = ref(false)
            const serverErrors = ref(null)
            const seasons = ref([])

            const questionnaire = ref({
                id: null,
                season_id: null,
                cycle: null,
                sections: 0,
                startDate: null,
                endDate: null,
                status: 0,
            })

            const CROMIS_STORE_MODULE_NAME = 'cromis-questionnaire'

            // Register module
            if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, questionnaireStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
            })

            onMounted(async () => {
                await store.dispatch('cromis-questionnaire/seasons', { sortBy: 'code', sortDesc: true })
                            .then(response => {
                                seasons.value = response.data.seasons
                                seasons.value.map((e) => {
                                    if(e.isRunning){
                                        questionnaire.value.season_id = e.id
                                        season_id.value = e.id
                                    }
                                })
                            })
                            .catch(error => {
                                console.log('errors,', error)
                            })
            })

            const changeSeason = () => {
                season_id.value = questionnaire.value.season_id
                resetForm()
            }

            const reachedEndDate = (endDate) => {
                if(moment(new Date()).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD'))
                    return true

                return false
            }

            const {
                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,
                season_id,

                // UI
                // resolveUserRoleVariant,
                // resolveUserRoleIcon,
                resolveStatusVariant,
            } = useQuestionnairesList()

            const submit = (bvModalEvt) => {
                bvModalEvt.preventDefault()
                serverErrors.value = null

                // Handle form submit
                if (questionnaire.value.id === null || questionnaire.value.id === 0)
                    handleCreate()
                else
                    handleUpdate(questionnaire.value)
            }

            const resetForm = () => {
                serverErrors.value = null
                questionnaire.value = {
                    id: null,
                    season_id: questionnaire.value.season_id,
                    cycle: null,
                    sections: 0,
                    startDate: null,
                    endDate: null,
                    status: 0,
                }
            }

            const invokeUpdateForm = (item) => {
                if(item.season.status !== 1 && item.status !== '1'){
                    context.root.$swal({
                        icon: 'warning',
                        title: 'Season Closed',
                        text: 'Edit not allowed on a closed season',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-warning',
                        },
                        buttonsStyling: false,
                    })
                }
                else{
                    questionnaire.value = {
                        id: item.id,
                        season_id: questionnaire.value.season_id,
                        cycle: item.cycle,
                        sections: item.sections.length,
                        startDate: item.startDate,
                        endDate: item.endDate,
                        status: (item.status === 1 || '' + item.status === '1')?? false,
                    }
                }
            }

            const handleCreate = async () => {
                saving.value = true

                await store.dispatch('cromis-questionnaire/create', questionnaire.value)
                    .then(response => {
                        refetch()
                        saving.value = false
                        resetForm()

                        context.root.$swal({
                            icon: 'success',
                            title: `Visit cycle ${response.data.cycle}, with questionnaire has been created!`,
                            text: `Now go to Questions menu, under Field Vistings to set the questionnaire's questions`,
                            showConfirmButton: true,
                            timer: 10000,
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })
                    })
                    .catch(error => {
                        saving.value = false
                        resetForm()
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const handleUpdate = async (item) => {
                saving.value = true

                await store.dispatch('cromis-questionnaire/update', { id: item.id, data: item })
                    .then(response => {
                        refetch()
                        saving.value = false

                        context.root.$swal({
                            icon: 'success',
                            text: `Changes to visit cycle and questionnaire has been made successfully!`,
                            showConfirmButton: true,
                            timer: 3000,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                            buttonsStyling: false,
                        })
                        resetForm()
                    })
                    .catch(error => {
                        saving.value = false
                        resetForm()
                        console.log(error)
                        if(error.response.status === 422){
                            serverErrors.value = error.response.data.errors
                        }
                        else{
                            context.root.$swal({
                                icon: 'error',
                                title: 'Server Error',
                                text: 'Something went wrong. See tech support',
                                showConfirmButton: true,
                                customClass: {
                                    confirmButton: 'btn btn-danger',
                                },
                                buttonsStyling: false,
                            })
                        }
                    })
            }

            const remove = async (id) => {
                await store.dispatch('cromis-questionnaire/remove', id)
                            .then(response => {
                                refetch()

                                context.root.$swal({
                                    icon: 'success',
                                    text: 'Questionnaire delete successfully!',
                                    showConfirmButton: true,
                                    customClass: {
                                        confirmButton: 'btn btn-outline-success',
                                    },
                                    buttonsStyling: false,
                                })
                            })
                            .catch(error => {
                                context.root.$swal({
                                    icon: 'error',
                                    title: 'Server Error',
                                    text: 'Something went wrong. See tech support',
                                    showConfirmButton: false,
                                    customClass: {
                                        confirmButton: 'btn btn-danger',
                                    },
                                    buttonsStyling: false,
                                })
                            })
            }

            return {
                saving,
                serverErrors,
                seasons,
                questionnaire,

                fetch,
                columns,
                perPage,
                currentPage,
                totalRecords,
                from,
                to,
                meta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                records,
                refetch,
                season_id,
                changeSeason,
                reachedEndDate,
                submit,
                resetForm,
                invokeUpdateForm,
                remove,

                // UI
                // resolveUserRoleVariant,
                // resolveUserRoleIcon,
                resolveStatusVariant,
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>